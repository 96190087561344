<template>
   <div>
       <div>正在跳转小程序，请稍候...</div>
       <div>如无响应，<a href="weixin://dl/business/?t=O1rK8gRuoAb">点击此处</a>立即跳转</div>
   </div>
</template>

<script>
export default {
    name: "routerMini",
    mounted() {
        location.href = 'weixin://dl/business/?t=O1rK8gRuoAb';
    }
}
</script>

<style scoped>

</style>