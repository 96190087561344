<template>
  <div>
    <div class="login-wrap">
      <vue-particles
        color="#fff"
        :particleOpacity="0.7"
        :particlesNumber="60"
        shapeType="circle"
        :particleSize="4"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        class="lizi"
        style="height:100%"
      >
      </vue-particles>
      <div class="ms-login">
        <div class="ms-title">后台管理系统</div>
        <el-form :model="user" :rules="rules" ref="ruleForm" label-width="0px" class="ms-content">
          <el-form-item prop="username" label="用户名"  label-width="60px">
            <el-input v-model="user.userName" placeholder="username">
            </el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码" label-width="60px">
            <el-input
                    type="password"
                    placeholder="password"
                    v-model="user.passWord"
                    @keyup.enter.native="login"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="re_number">记住密码</el-checkbox>
          </el-form-item>
          <div class="login-btn">
            <el-button type="primary" @click="login">登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>

</template>

<script>
import {Login} from '@/apis/user';
import crypto from '@/utils/crypto';
import { mapMutations } from 'vuex';
export default {
  name: 'login',
  data () {
    return {
      user: {
        userName: '',
        passWord: ''
      },
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        passWord: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      re_number: false
    }
  },
  mounted () {
    if (sessionStorage.getItem('userInfo') && JSON.parse(crypto.decrypt(sessionStorage.getItem('userInfo'))).token) {
      this.$router.push('/');
    }
    if (localStorage.getItem('re_number') === '1') {
      console.log(localStorage.getItem('re_number'));
      this.user.userName = JSON.parse(crypto.decrypt(localStorage.getItem('user')))['userName'];
      this.user.passWord = JSON.parse(crypto.decrypt(localStorage.getItem('user')))['passWord'];
      this.re_number = true;
    }
  },
  methods: {
    ...mapMutations('user', ['setUserInfo']),
    async login () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let loginMsg = JSON.parse(JSON.stringify(this.user)); // 对登录的信息进行处理
          loginMsg.passWord = crypto.MD5(this.user.passWord);
          Login(loginMsg).then(res => {
            console.log(res);
            if (res && res.data && res.data.code === 0) {
              // code返回时0的时候
              let sessionUser = {};
              sessionUser.adminData = res.data.data.adminLoginVO;
              sessionUser.permission = res.data.data.permissionTreeList;
              // sessionUser.token = getCookie("token");
              sessionUser.token = res.data.data.adminLoginVO.token;
              let values = crypto.encrypt(JSON.stringify(sessionUser, null)).toString();
              console.log(sessionUser.adminData);
              sessionStorage.setItem('activeIndex', '1');
              sessionStorage.setItem('userInfo', values);
              // sessionStorage.setItem('username', sessionUser.adminData.username);
              sessionStorage.setItem('username', sessionUser.adminData.username);
              sessionStorage.setItem('userId', sessionUser.adminData.adminId);
              sessionStorage.setItem('roleid', sessionUser.adminData.roleid);
              sessionStorage.setItem('nickname', sessionUser.adminData.nickname);

              this.setUserInfo(sessionUser); // 一定要保存用户的登录信息

              // 记住密码
              if (this.re_number) {
                let reLoginMsg = crypto.encrypt(JSON.stringify(this.user));
                localStorage.setItem('user', reLoginMsg);
                localStorage.setItem('re_number', 1);
              } else {
                localStorage.removeItem('user');
                localStorage.setItem('re_number', 0);
              }
              this.$router.push({path: '/medical'});
            } else {
              this.$message({message: res.data.msg, type: 'warning'});
            }
          });
        } else {

        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>

  .row{
    display: flex;
    justify-content: center;
  }
  .card-primary {
    border-top: 2px solid #6777ef;
  }
  h4 {
    margin: 0;
  }
  .login{
    background: #6777ef;
    width: 100%;
  }

  .card-body {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;

  }
  .card .card-header {
    line-height: 30px;
    -ms-grid-row-align: center;
    align-self: center;
    width: 100%;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #eae7e7;
    text-align: center;
    justify-content: center;
  }
   .card .card-body, .card .card-footer {
    background-color: transparent;
    padding: 20px 25px;

  }

  .login_container {
    background-image: linear-gradient(-180deg, #1a1454 0%, #0e81a5 100%);
    /*background-image: url("../images/bg_login.png");*/
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .login-wrap {
    background-image: linear-gradient(-180deg, #1a1454 0%, #0e81a5 100%);
    /*background-image: url("../images/bg_login.png");*/
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
  }
  .ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
  }
  .ms-content {
    padding: 30px 30px;
   /deep/ label.el-form-item__label {
      color: white;
    }
  }
  .login-btn {
    text-align: center;
  }
  .login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
  }
  .login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
  }
</style>
